var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"jad-data-table jad-orders-table",attrs:{"headers":_vm.headers,"items":_vm.items,"dense":_vm.dense,"loading":!_vm.hideLoading && _vm.loading,"options":_vm.pageOptions,"server-items-length":_vm.serverItemsLength,"mobile-breakpoint":"0","must-sort":"","footer-props":{
    itemsPerPageOptions: [25, 50, 100, -1],
  }},on:{"update:options":_vm.updateOptions,"update:page":_vm.scrollToTop},scopedSlots:_vm._u([{key:"item.attachmentCount",fn:function(ref){
  var value = ref.value;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [(value > 0)?_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("$attachment")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(value)+" Attachment(s)")])])]}},{key:"item.displayId",fn:function(ref){
  var value = ref.value;
  var item = ref.item;
return [_c('router-link',{staticClass:"d-block text-no-wrap",attrs:{"to":{ name: 'order-edit', params: { orderId: item.id } }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.customer.abbr",fn:function(ref){
  var value = ref.value;
  var item = ref.item;
return [_c('router-link',{staticClass:"d-block text-truncate",staticStyle:{"max-width":"10em"},attrs:{"to":{
        name: 'customer',
        params: { customerId: item.customer.id },
      },"title":item.customer.name}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.quoteNo",fn:function(ref){
      var value = ref.value;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"6.5em"},attrs:{"title":value}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.tags",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value.map(function (v) { return v.abbr; }).join(', '))+" ")]}},{key:"item.note",fn:function(ref){
      var value = ref.value;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate",staticStyle:{"max-width":"20em"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)},[_c('div',{staticStyle:{"white-space":"pre","font-size":"90%","line-height":"1.5"}},[_vm._v(" "+_vm._s(value)+" ")])])]}},{key:"item.netAmount",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('order-items-preview',{attrs:{"order":item}},[_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency))+" ")])]}},{key:"item.postedAmount",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency))+" ")]}},{key:"item.unpostedAmount",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency))+" ")]}},{key:"item.deliveryAddress.displayAddress",fn:function(ref){
      var value = ref.value;
return [_c('a',{attrs:{"href":_vm.getMapUrl(value),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.updatedAt",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("datetimeShort")(value))+" ")]}},{key:"item.updatedBy.username",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(value))])]}}],null,true)},[_c('v-list-item',{staticClass:"pa-0",attrs:{"dark":""}},[_c('v-list-item-avatar',[_c('img',{attrs:{"src":item.updatedBy.avatarUrl,"alt":item.updatedBy.username}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.updatedBy.firstName)+" "+_vm._s(item.updatedBy.lastName)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.updatedBy.username))])],1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }