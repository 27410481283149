export const filterObject = (
  obj: { [key: string]: unknown },
  filter: (key: string, value: unknown) => boolean
) =>
  Object.entries(obj).reduce(
    (prev, [key, value]) => ({
      ...prev,
      ...(filter(key, value) ? { [key]: value } : {}),
    }),
    {}
  )
