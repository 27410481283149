
































































































import Vue from 'vue'
import log from '@/log'
import { DataOptions } from 'vuetify'
import deepFreeze from 'deep-freeze'
import debounce from 'lodash.debounce'

// components
import OrdersTable from '@/components/orders/OrdersTable.vue'
import JTableSearch from '@/components/controls/JTableSearch.vue'

// models
import Order from '@/models/Orders/Order'

// api
import { ListOpts, ListPaginate } from '@/api/types'

// message bus
import MessageBus from '@/message-bus'

// utils
import { filterObject } from '@/utils/object-filter'

// contants
const KEYBOARD_DEBOUNCE_WAIT = 1000
const DEFAULT_DENSE = true

export default Vue.extend({
  metaInfo() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      title: (this as any).title as string,
    }
  },
  components: {
    OrdersTable,
    JTableSearch,
  },
  props: {
    where: {
      type: Object,
      default: () => ({}),
    },

    dense: {
      type: Boolean,
      default: DEFAULT_DENSE,
    },

    hideAddButton: Boolean,
  },
  data() {
    return {
      orders: [] as ReadonlyArray<Order>,
      totalOrders: 0,
      cancelled: false,

      pageOptions: {
        page: this.$route.query.p || 1,
        itemsPerPage: this.$route.query.pp || 50,
        sortBy: ['receivedDate'],
        sortDesc: [true],
      } as DataOptions,

      isFetching: false as false | 'orders' | 'download',
      errors: [] as Error[],

      searchDialog: false,
      fields: [
        { field: 'id', name: 'ID', type: 'number' },
        { field: 'receivedDate', name: 'Received', type: 'date' },
        { field: 'netAmount', name: 'Net', type: 'number', format: 'currency' },
      ],

      onSearchInput: debounce(
        (this as any).updateSearchQuery, // eslint-disable-line @typescript-eslint/no-explicit-any
        KEYBOARD_DEBOUNCE_WAIT
      ),
    }
  },
  computed: {
    title(): string {
      return this.cancelled ? 'Cancelled Orders' : 'Orders'
    },
    apiFn(): ListPaginate<Order> {
      return this.cancelled ? this.$api.orders.cancelled : this.$api.orders
    },
    search(): string {
      const q = this.$route.query.q || ''
      return Array.isArray(q) ? q.join('') : q.toString()
    },
    listOpts(): ListOpts {
      const v = {
        ...filterObject(
          this.$route.query,
          key => !['p', 'pp', 'sort'].includes(key)
        ),
        ...(this.where || {}),

        p: (this.pageOptions.page || 1).toString(),
        pp: (this.pageOptions.itemsPerPage || 50).toString(),
        sort: (this.pageOptions.sortBy || ['receivedDate'])
          .map((field, i) =>
            [field, (this.pageOptions.sortDesc || [true])[i] ? '!' : ''].join(
              ''
            )
          )
          .join(','),
      }

      return v
    },
  },
  watch: {
    cancelled: {
      immediate: true,
      handler() {
        this.fetchOrders()
      },
    },
    listOpts: {
      immediate: true,
      handler(value: ListOpts, oldValue: ListOpts) {
        if (JSON.stringify(value) !== JSON.stringify(oldValue))
          this.fetchOrders()
      },
    },
    pageOptions: {
      deep: true,
      handler(value: DataOptions) {
        const query = JSON.parse(JSON.stringify(this.$route.query))

        query.p = (value.page || 1).toString()
        query.pp = (value.itemsPerPage || 50).toString()
        query.sort = (value.sortBy || ['receivedDate'])
          .map((field, i) =>
            [field, (value.sortDesc || [true])[i] ? '!' : ''].join('')
          )
          .join(',')

        if (query.p === '1') delete query.p
        if (query.pp === '50') delete query.pp
        if (query.sort === 'receivedDate!') delete query.sort

        if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
          this.$router.replace({ query })
        }
      },
    },
  },
  methods: {
    setOrders(orders: Order[], count: number): void {
      this.orders = deepFreeze(orders)
      this.totalOrders = count
    },
    refreshOrders(): void {
      this.fetchOrders()
    },
    updateSearchQuery(v: string): void {
      const queries = JSON.parse(JSON.stringify(this.$route.query))
      queries.q = v || ''
      if (!queries.q) delete queries.q
      this.$router.replace({ query: queries })
    },
    fetchOrders(): void {
      this.isFetching = 'orders'
      this.errors = []

      this.apiFn
        .list(this.listOpts)
        .then(([orders, count]) => {
          this.setOrders(orders, count)
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    exportCsv() {
      this.isFetching = 'download'
      this.errors = []

      this.$api.orders
        .download(this.listOpts, 'orders')
        .then(() => {
          MessageBus.success('download started!')
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
  },
})
