// models
import Order from '@/models/Orders/Order'

// types
import {
  ID,
  DateOnly,
  Product,
  OrderItemInput,
  ProductCondition,
} from '@/types'

export class OrderItem {
  id: ID

  product: Product
  quantity: number
  grossAmount: number

  condition?: ProductCondition

  serialNo?: string
  note?: string
  fmvAmount?: number
  overridePostedDate?: DateOnly

  estPostedDate?: DateOnly
  postedDate?: DateOnly
  adjustmentAmount: number
  adjustmentWeight: number
  netAmount: number
  postedAmount: number
  revenueAmount: number
  unpostedAmount: number

  order: Order

  constructor(
    order: Order,
    {
      id,
      product,
      quantity,
      grossAmount,
      condition,
      serialNo,
      note,
      overridePostedDate,
      fmvAmount,

      adjustmentAmount,
      adjustmentWeight,
      postedDate,
      netAmount,
      postedAmount,
      unpostedAmount,
      revenueAmount,
    }: OrderItemInput
  ) {
    this.order = order

    this.id = id
    this.quantity = quantity
    this.grossAmount = grossAmount
    this.product = product

    this.condition = condition

    this.serialNo = serialNo
    this.note = note
    this.fmvAmount = fmvAmount
    this.overridePostedDate = overridePostedDate

    this.adjustmentAmount = adjustmentAmount
    this.adjustmentWeight = adjustmentWeight
    this.postedDate = postedDate
    this.netAmount = netAmount
    this.postedAmount = postedAmount
    this.unpostedAmount = unpostedAmount
    this.revenueAmount = revenueAmount
  }

  get unitPrice(): number {
    const grossAmount = this.grossAmount || 0
    if (!this.quantity) return 0
    return grossAmount / this.quantity
  }

  get unitNetPrice(): number {
    if (!this.quantity) return 0
    return this.netAmount / this.quantity
  }

  get isPosted(): boolean {
    return !!this.postedDate
  }

  public static fromJSON(order: Order, input: OrderItemInput) {
    return new OrderItem(order, input)
  }
}

export default OrderItem
