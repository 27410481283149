
































































































































import Vue, { PropType } from 'vue'
import { DataOptions } from 'vuetify'

// components
import OrderItemsPreview from '@/components/orders/previews/OrderItemsPreview.vue'

// models
import Order from '@/models/Orders/Order'

// utils
import { exportCsv, ExportableDataTableHeader } from '@/utils/export-data-table'
import { getMapUrl } from '@/utils/geomaps'

const EXPORT_CSV_FILENAME = 'orders'

export default Vue.extend({
  components: {
    OrderItemsPreview,
  },
  props: {
    items: Array as PropType<Order[]>,

    loading: Boolean,
    dense: Boolean,

    hideLoading: Boolean,

    pageOptions: {
      type: Object as PropType<DataOptions>,
      default: () => ({
        page: 1,
        itemsPerPage: 50,
        sortBy: ['receivedDate'],
        sortDesc: [true],
      }),
    },
    serverItemsLength: Number,
  },
  data: () => ({}),
  computed: {
    headers(): ExportableDataTableHeader[] {
      return [
        {
          text: '',
          value: 'attachmentCount',
          class: 'px-0',
          cellClass: 'pl-2 pr-0',
          hideInExport: true,
        },
        {
          text: 'ID',
          value: 'displayId',
          class: 'sticky-left',
          cellClass: 'sticky-left',
          exportType: 'text',
        },
        { text: 'Cat', value: 'category.abbr' },
        {
          text: 'Received',
          value: 'receivedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Quote',
          value: 'quoteNo',
          width: '4em',
          cellClass: 'text-truncate',
        },
        { text: 'Co', value: 'company.abbr' },
        { text: 'Customer', value: 'customer.abbr' },
        { text: 'Net', value: 'netAmount', align: 'end' },

        // TODO: show categories (TP, OTHER, SERVICE)

        {
          text: 'Processed',
          value: 'processedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Shipped',
          value: 'shippedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Delivered',
          value: 'deliveredDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Installed',
          value: 'installedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Ship Term',
          value: 'shippingTerm.abbr',
          align: 'start',
          cellClass: 'text-no-wrap',
        },

        { text: 'Order', value: 'orderNo' },
        { text: 'PO', value: 'poNo' },

        // TODO: ItemCount

        { text: 'Posted', value: 'postedAmount', align: 'end' },
        { text: 'Unposted', value: 'unpostedAmount', align: 'end' },

        {
          text: 'Tags',
          value: 'tags',
          align: 'start',
          cellClass: 'text-no-wrap',
        },
        { text: 'Note', value: 'note', align: 'start' },

        {
          text: 'Min Posted',
          value: 'minPostedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Max Posted',
          value: 'maxPostedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },

        { text: 'Salesperson', value: 'salesperson.username' },
        { text: 'Sales Area', value: 'salesArea.abbr' },
        {
          text: 'Delivery Address',
          value: 'deliveryAddress.displayAddress',
          cellClass: 'text-no-wrap',
        },

        {
          text: 'Updated At',
          value: 'updatedAt',
          cellClass: 'text-no-wrap',
        },
        { text: 'Updated By', value: 'updatedBy.username' },
      ]
    },
  },
  methods: {
    exportCsv(): void {
      exportCsv(this.items, this.headers, EXPORT_CSV_FILENAME)
    },
    scrollToTop() {
      this.$vuetify.goTo('.jad-data-table tbody tr', {
        offset: 50,
        container: '.v-data-table__wrapper',
      })
    },
    updateOptions(v: unknown) {
      this.$emit('update:options', v)
    },
    getMapUrl,
  },
})
