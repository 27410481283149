































































































import Vue from 'vue'
import log from '@/log'

import ProductImage from '@/components/controls/ProductImage.vue'

import { getDisplayId } from '@/models/Orders/Order'
import OrderItem from '@/models/OrderItems/OrderItem'

import { ID } from '@/types'

export default Vue.extend({
  components: {
    ProductImage,
  },
  props: {
    order: Object,
    width: {
      type: [Number, String],
      default: 500,
    },
  },
  data: () => ({
    menu: false,
    orderId: undefined,
    items: [] as OrderItem[],

    isFetching: false,
  }),
  watch: {
    menu: {
      handler(val: boolean): void {
        if (val && this.orderId !== this.order.id) {
          this.orderId = this.order.id
          this.fetchItems(this.order.id)
        }
      },
    },
  },
  computed: {},
  methods: {
    getDisplayId,
    fetchItems(orderId: ID): Promise<OrderItem[]> {
      this.isFetching = true

      return this.$api.orders
        .getItems(orderId)
        .then(orderItemInputs =>
          orderItemInputs.map(z => OrderItem.fromJSON(this.order, z))
        )
        .then(orderItems => {
          this.items = orderItems
          return orderItems
        })
        .catch(err => {
          log.error(err)
          throw err
        })
        .finally(() => {
          this.isFetching = false
        })
    },
  },
})
